<template>
  <SidebarMenuTitle
    title="Cases"
    active-route="/"
    icon="briefcase"
    :show-plus="true"
    :check-menu="getAllCases"
    :test-info="{
      testTitle: 'sidebar-cases',
      testPlus: 'sidebar-add-case'
    }"
    @plus-action="addNewCase"
  />
  <SidebarMenuList
    :menu="getAllCases"
    item-route="/case/{oid}"
    :edit-mode="true"
    link-limit="20"
    :test-info="{
      caseLink: 'test-sidebar-case',
      editPanel: 'test-case-panel',
      closePanel: 'test-case-panel-close',
      deleteItem: 'test-open-delete-case',
      renameItem: 'test-rename-case',
      upgradeItems: 'test-upgrade-cases',
      showAllItems: 'sidebar-show-cases'
    }"
    :show-upgrade="casesLimit"
  />
  <SidebarMenuTitle
    title="Monitoring"
    active-route="/monitoring"
    icon="activity"
    :show-plus="true"
    :check-menu="monitoredCasesSorted"
    :beta="true"
    :test-info="{
      testTitle: 'sidebar-monitoring',
      testPlus: 'sidebar-add-monitoring'
    }"
    @plus-action="$router.push({ path: '/monitoring/settings' })"
  />
  <SidebarMenuList
    :menu="monitoredCasesSorted"
    item-route="/monitoring/review/{oid}"
    link-limit="18"
    :test-info="{
      caseLink: 'test-sidebar-monitoring',
      showAllItems: 'sidebar-show-monitoring-cases'
    }"
  />
  <SidebarAlert local-storage="monitoringBeta">
    <template v-slot:title>Monitoring (beta) is now available</template>
    <template v-slot:text>
      Gamayun now has a new monitoring feature for receiving subject profile updates without performing manual checks.
      <br><br>
      Users are informed of alterations via email at set intervals, and the whole history of profile changes is automatically stored with the case, meaning you can easily add them to a report if necessary.
      <br><br>
      As the function is still in its beta version, it currently only supports LinkedIn profiles, with other major platforms being added to the feature soon.
      <br><br>
      We’d greatly appreciate any feedback users can give us on the feature’s usability and functionality.
    </template>
  </SidebarAlert>

  <SendModal
    v-if="openCasesLimitModal"
    title="You have reached the limit of cases"
    subtitle="Delete the old case or buy new ones"
    confirmText="Buy new ones"
    declineText="Cancel"
    @closeModal="openCasesLimitModal = false"
    @confirmAnswer="openCasesLimitModal = false; $router.push({ query: { ...$route.query, profile: 'plan' } });"
  />
</template>

<script>
import SidebarMenuTitle from '@/components/sidebar/MenuTitle';
import SidebarMenuList from '@/components/sidebar/MenuList';
import SidebarAlert from '@/components/sidebar/Alert';
import SendModal from '@/components/UI/SendModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    SidebarMenuTitle,
    SidebarMenuList,
    SidebarAlert,
    SendModal
  },
  data () {
    return {
      openCasesLimitModal: false
    };
  },
  async created () {
    this.setAllCases();
    if (!this.monitoredCasesSorted.length) this.getMonitoredCases();
  },
  computed: {
    ...mapGetters(['accountState', 'getAllCases', 'monitoredCasesSorted']),

    casesLimit () {
      const getLimit = this.accountState.subscription.options['gamayun.cases.limit'];
      return getLimit || null;
    }
  },
  methods: {
    ...mapActions(['setAllCases', 'setAddNewCase', 'getMonitoredCases']),

    addNewCase () {
      if (this.getAllCases.length === this.casesLimit) this.openCasesLimitModal = true;
      else this.setAddNewCase();
    }
  }
};
</script>
